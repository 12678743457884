<script>
	import { topNavVisible } from '$lib/stores/storesDashboard';

	export let value;
	export let bindedValue;
	export let icon;
	export let iconSize;
	export let color;

	function onClick() {
		bindedValue = value === bindedValue ? 0 : value;
	}
</script>

<button
	on:click={onClick}
	style="background-color: {color};"
	class="text-md text-white text-center cursor-pointer"
	class:active={bindedValue === value}
	><span class="{icon} {iconSize}" />
	<slot />
</button>

<style lang="scss">
	@import '../../styles/variables.scss';

	button {
		flex: 1;

		&.active {
			background-color: $color-select !important;
		}
	}
</style>
