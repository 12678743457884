<script>
	import { isMobile } from '$lib/stores/storesShared';

	export let position;
	let innerHeight;

	$: sidebarHeight = $isMobile ? `auto` : `${innerHeight - 100}px`;
</script>

<svelte:window bind:innerHeight />

<aside
	class="sidebar {position} shrink-0 w-full lg:w-96 m-0 lg:m-5 p-0 lg:rounded  z-40 shadow-lg lg:shadow-none border-none lg:border overflow-y-auto"
>
	<div
		class="sidebar-wrapper overflow-y-auto lg:p-5 lg:border lg:rounded bg-white relative"
		style="height:{sidebarHeight}"
	>
		<slot />
	</div>
</aside>

<style lang="scss">
	.sidebar {
		font-size: 14px;
	}
</style>
