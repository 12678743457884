<div>
	<slot />
</div>

<style>
	div {
		height: 60px;
		display: none;
	}

	@media screen and (max-width: 1024px) {
		div {
			display: flex;
		}
	}
</style>
