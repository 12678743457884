import { writable, readable } from 'svelte/store';

export const tabData = readable([
	{ label: 'Beschreibung', value: 'beschreibung' },
	{ label: 'Berechnungsvorschrift', value: 'berechnungsvorschrift' },
	{ label: 'Ergebnisse', value: 'ergebnisse' },
	{ label: 'Anwendungsbereiche', value: 'anwendungsbereiche' },
	{ label: 'Referenzen', value: 'referenzen' }
]);

export let selectedIndicatorTab = writable('beschreibung');
export let selectedDataSet = writable('');

// state navigation elements
export const navVisibleIndikatoren = writable(0);

export let selectedIndicator = writable('');

export const indicatorTypes = [
	{
		type: 'kern',
		label: 'Kernindikatoren',
		color: '#A2C671',
		description:
			'Diese Indikatoren sind für das Flächenmonitoring essentiell. Sie werden bereits als Teil der Nachhaltigkeitsindikatoren Deutschlands für Berichtszwecke genutzt (SDG 11.1a-c Anstieg der Siedlungs- und Verkehrsfläche, Freiraumverlust, Siedlungsdichte). Sie werden um weitere für ein qualifizierendes Flächenmonitoring unentbehrliche Indikatoren (insb. Bodenversiegelung, Siedlungsflächenausstattung) ergänzt. Für diese Indikatoren sind die entsprechenden Produkte aus Fernerkundungsdaten bereitzustellen, um die Nutzbarkeit dieser für das Siedlungsflächenmonitoring zu demonstrieren.'
	},
	{
		type: 'zusatz',
		label: 'Zusatzindikatoren',
		color: '#2CA89F',
		description:
			'Diese Indikatoren bilden die 2. Ebene der Indikatoren für das Flächenmonitoring. Sie haben zwar einen hohen Mehrwert für das Flächenmonitoring, können aber standardmäßig (noch) nicht alle für ein bundesweites Monitoring eingesetzt werden: z.B. Brachflächen, Grünvolumen. Bei der Auswahl der Indikatoren wird die Darstellung der Machbarkeit für jeden Indikator individuell angestrebt.'
	},
	{
		type: 'raum',
		label: 'Raumstrukturindikatoren',
		color: '#2F84BC',
		description:
			'Diese Indikatoren bilden raumstrukturelle Merkmale ab, wie z.B. Zersiedelungsmaße, Kompaktheitsmaße, Grünflächenvernetzung. Sie können aber aufgrund von Limitationen der Fernerkundungsdaten nicht immer hinreichend genau berechnet oder flächendeckend abgebildet werden.'
	},
	{
		type: 'fallbeispiel',
		label: 'Fallbeispielindikatoren',
		color: '#934C90',
		description:
			'Sind Indikatoren, die regional von hoher Bedeutung sind oder aufgrund der Datenlage nur für Teilräume und nicht bundesweit (mit vertretbarem Aufwand) ermittelt werden können: z.B. Indikatoren zur Bebauungs- und Gebäudestruktur, Potenzialflächen, Grünvitalität. Die Machbarkeit dieser Indikatoren wird aufgrund der Datenlage anhand von Fallbeispielen erläutert.'
	}
];

export const indicators = {
	'baulich-gepraegte-flaechen': {
		label: 'Baulich geprägte Flächen',
		indicators: [
			{
				label: 'Δ Anstieg baulich geprägte Flächen',
				value: 'anstieg-baulich-gepraegte-flaechen',
				type: 'kern'
			},
				{
				label: 'Δ Neue Bebauung außerhalb von Ortslagen',
				value: 'neue-bebauung-ausserhalb-ortslagen',
				type: 'kern'
			},
				{
				label: 'Δ Bebauungsdichte',
				value: 'veraenderung-nutzungsintensitaet',
				type: 'kern'
			},
				{
				label: 'Δ Überbauung von Landwirtschaftsfläche',
				value: 'ueberbauung-landwirtschaftsflaeche',
				type: 'zusatz'
			},
				{
				label: 'Δ Neue Bebauung auf geschützten Gebieten',
				value: 'neue-bebauung-schutzgebiete',
				type: 'zusatz'
			},
			{ label: 'Δ Versiegelung und Veränderung ', value: 'versiegelung-veraenderung', type: 'kern' }
		]
	},
	'vegatations-und-gruenflaechen': {
		label: 'Vegetations-und Grünflächen',
		indicators: [
			{
				label: 'Δ Vegetationsbedeckung der Bezugsfläche', // formerly 'Vegetationsbedeckung'
				value: 'veraenderung-gruenueberdeckung',
				type: 'zusatz'
			},
			{ label: 'Δ Vegetationsbedeckung innerhalb von Ortslagen', value: 'veraenderung-stadtgruen', type: 'zusatz' }, // formerly 'Stadtgrün'
			{
				label: 'Δ Vegetationsbedeckung außerhalb von Ortslagen',
				value: 'gruenueberdeckung-siedlungsfreiraum',
				type: 'raum'
			},
			{
				label: 'Δ Grünflächenausstattung der Bezugsfläche', // formerly 'Grünflächenausstattung'
				value: 'veraenderung-gruenflaechenausstattung',
				type: 'zusatz'
			},
			{
				label: 'Δ Freiraum außerhalb der Ortslage',
				value: 'veraenderung-freiraum-ausserhalb-ortslage',
				type: 'raum'
			},
			{
				label: 'Δ Grünanteil im Freiraum',
				value: 'veraenderung-gruenanteil-freiraum',
				type: 'raum'
			},
			{ label: 'Grünflächenvernetzung', value: 'gruenflaechenvernetzung', type: 'raum' },
			{
				label: 'Erreichbarkeit von Grünflächen',
				value: 'erreichbarkeit-gruenflaechen',
				type: 'raum'
			},
			{ label: 'Grünvolumen', value: 'gruenvolumen', type: 'fallbeispiel' }
		]
	},
	nachverdichtungspotenziale: {
		label: 'Nachverdichtungspotenziale',
		indicators: [
			{ label: 'Unbebaute Grundstücke', value: 'unbebaute-grundstuecke', type: 'fallbeispiel' },
			{ label: 'Grundstücksauslastung', value: 'grundstuecksauslastung', type: 'fallbeispiel' },
			{ label: 'Potenzialflächen', value: 'potenzialflaechen', type: 'fallbeispiel' }
		]
	},
	zersiedelung: {
		label: 'Zersiedelung',
		indicators: [
			{ label: 'Kompaktheit', value: 'kompaktheit', type: 'raum' },
			{
				label: 'Integration neuer baulich geprägter Flächen in bestehende baulich geprägte Flächen',
				value: 'integration',
				type: 'raum'
			},
			{
				label: 'Anbindungen neuer baulich geprägter Flächen an den ÖV',
				value: 'anbindungen-oev',
				type: 'raum'
			},
			{ label: 'Nutzungsmischung', value: 'nutzungsmischung', type: 'fallbeispiel' },
			{ label: 'Leapfrog Index', value: 'leapfrog-index', type: 'raum' }
		]
	},
	freiraumschutz: {
		label: 'Freiraumschutz',
		indicators: [
			{ label: 'Unzerschnittene Räume', value: 'unzerschnittene-raeume', type: 'zusatz' },
			{
				label: 'Veränderung von Landwirtschaftsfläche',
				value: 'veraenderung-landwirtschaftsflaeche',
				type: 'zusatz'
			},
			{ label: 'Veränderung von Waldfläche', value: 'veraenderung-waldflaeche', type: 'zusatz' },
			{
				label: 'Veränderung von Wasserfläche',
				value: 'veraenderung-wasserflaeche',
				type: 'zusatz'
			},
			{ label: 'Rohstoffabbauflächen', value: 'rohstoffabbauflaechen', type: 'fallbeispiel' },
			{
				label: 'Freiraumverfügbarkeit außerhalb der Ortslage',
				value: 'freiraumverfuegbarkeit-außerhalb-ortslage',
				type: 'kern'
			},
			{
				label: 'Vegetationsbedeckung des Freiraumes',
				value: 'gruenueberdeckung-des-freiraumes',
				type: 'raum'
			}
		]
	},
	bebauungsstruktur: {
		label: 'Bebauungsstruktur',
		indicators: [
			{ label: 'Siedlungsumringe', value: 'siedlungsumringe', type: 'fallbeispiel' },
			{ label: 'Gebäudehöhe', value: 'gebaeudehoehe', type: 'fallbeispiel' },
			{ label: 'Gebäudeabstand', value: 'gebaeudeabstand', type: 'fallbeispiel' },
			{
				label: 'Bebauungsdichte des Siedlungsgebietes',
				value: 'bebauungsdichte',
				type: 'fallbeispiel'
			}
		]
	}
};
