<script>
	import { visibleSidebarEl } from '$lib/stores/storesDashboard';
	import { navVisibleDatensaetze } from '$lib/stores/storesDatensaetze';
	import { navVisibleIndikatoren } from '$lib/stores/storesIndikatoren';

	import { isMobile } from '$lib/stores/storesShared';
	export let title;
	export let value;
	export let bindedValue;
	export let icon;
	export let iconSize;

	function onClick() {
		$visibleSidebarEl = 1; // Dashboard
		$navVisibleDatensaetze = 0; // Datensaetze
		$navVisibleIndikatoren = 0; // Indikatoren
	}
</script>

<div class="mb-9" class:mob-hidden={value !== bindedValue}>
	{#if $isMobile}
		<div class="text-right cursor-pointer icon-close pr-4 pt-4 text-lg" on:click={onClick} />
	{/if}
	<div class="mb-0 lg:mb-2 pt-4 pl-4 lg:pl-0">
		<span class="icon-{icon} {iconSize}" />
		<span class="font-bold">{title}</span>
	</div>

	<slot />
</div>

<style>
	@media screen and (max-width: 1024px) {
		.mob-hidden {
			display: none;
		}
	}
</style>
