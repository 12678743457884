import { writable, readable } from 'svelte/store';

export const tabData = readable([
	{ label: 'Historie', value: 'historie' },
	{ label: 'Qualitätsbericht', value: 'qualitaetsbericht' },
	{ label: 'Anwendungsbereiche', value: 'anwendungsbereiche' },
	{ label: 'Interaktive Karte', value: 'karte' },
	{ label: 'Referenzen', value: 'referenzen' }
]);

export let selectedDataTab = writable('historie');
export let selectedDataSet = writable('');

// state navigation elements
export const navVisibleDatensaetze = writable(0);
